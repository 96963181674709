/*
----------------------------
Bilan
----------------------------
*/
#bilan{
	width: 95%;
	margin: 20px auto;
	font-size: 11px;
}
#bilan .table-bordure{
	border-top: 2px solid #000;
	width: 100%;
	margin-bottom: 10px;
}
#bilan .table-bordure td{
	padding: 8px;
}
#bilan .table-bordure > thead > tr > th, 
#bilan .table-bordure > tbody > tr > th, 
#bilan .table-bordure > tfoot > tr > th, 
#bilan .table-bordure > thead > tr > td, 
#bilan .table-bordure > tbody > tr > td, 
#bilan .table-bordure > tfoot > tr > td,
#bilan .panel-default{
	border: 2px solid #000 !important;
}
#bilan .border-right-w{
	border-right:2px solid white !important
}
#bilan .border-bot-w{
	border-bottom:2px solid white !important
}
#bilan .views-field-field-prenom-1{
	margin-bottom: 20px;
}
#bilan thead{
	padding-bottom: 10px;
}
#bilan .panel{
	height:160px;
	border-radius: 0;
}
#bilan .marged{
	margin-bottom: 10px;
}
#bilan .header{
	margin-bottom: 10px;
}
#bilan .header td{
	vertical-align: top;
	padding: 0 20px 0 0;
}
#bilan .logo{
	width: 15%;
}
#bilan .titre{
	width:50%;
}

#bilan .header h3{
	margin-top: 0;
	margin-bottom: 5px;
}
#bilan .titre-formation{
	font-size: 20px;
	font-weight: bold;
}
#bilan .views-field-nothing-2{
	font-size: 11px;
}
#bilan thead tr th,
#bilan .panel-heading{
	position: relative;
	/*background: #EDEDED url(../images/bg_emarg.png) repeat;*/
	font-weight: bold;
	padding: 0;
}
#bilan .ontop{
	position: absolute;top: 5px; left: 5px;
}
#bilan .mini{
	width: 50%;
	position: relative;
	padding:0 !important;
}
#bilan .mini .ontop{
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
#bilan .inner-table-th{
	width: 100%;
}
#bilan .footer{
	font-size:11px; text-align:center;
	margin: 0;
	padding:10px 0 0 0;
	border: 0; 
}