#block-views-actualites-block{
	font-size: 0.9rem;
	.list-group-item{
		a{
			display: block;
			margin-right: 0.5rem;
			color: $dark;
			img{
				border: 1px solid #CCC;
				padding: 0.25rem;
			}
		}
	}
}
.view-display-id-actu_liste{
	a{
		font-size: 0.9em;
		color: $dark;
	}
	.views-field-field-image{
		img{
			width: 100%;
			height: auto;
		}
	}
}

.node-type-article{
	.field-name-field-image{
		float: right;
		margin-left: 1rem;
	}
}