h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  font-family: 'Cabin';
  color: $primary;
}


#block-system-main-menu {
  border-bottom: 1px solid #CCC;
  margin-bottom: 1rem;
  ul li.leaf{
    list-style: none;
  }
  ul.nav{
    li{
      a.nav-link{
        display: flex;
        align-items: center;
        text-align: left;
        &:before{
          padding-right: 1rem;
          font-family: "Font Awesome 6 Pro";
          font-size: 1.25rem;
        }
      }
      a.accueil:before{
        content: '\f015';
      } 
      a.catalogue:before{
        content: '\f5db';
      }
      a.agenda:before{
        content: '\f073';
      }
      a.actualites:before{
        content: '\f1ea';
      }
      a.contacts:before{
        content: '\f2b9';
      }
      a.qui-sommes-nous:before{
        content: '\f2bb';
      }
      a.suggestions:before{
        content: '\e1e7';
      }
      
    }
   
  }

}

#block-user-login{
  h2{
    font-size: 1.5rem;
  }
  .item-list{
    ul{
      li{
        font-size: 0.9em;
      }
    }
  }
}


@media (min-width:992px) {    
  .vertical-nav {
      position: fixed;
      top: 56px;
      left: 0;
      width: 200px;
      height: 100%;
      background-color: #f8f8f8;
      overflow-y: auto;
      padding-top: 30px
    }
}


.special-heading-border{
  height: 2.6em;
  position: relative;
  overflow: hidden;
  .special-heading-inner-border{
    opacity: 0.4;
    display: block;
    width: 100%;
    margin-left: 15px;
    border-top-style: solid;
    border-top-width: 1px;
    border-color:#ffffff;
    position: relative;
    top: 50%;
  }
}


fieldset {
  border: 1px groove $card-border-color !important;
  padding: 0 $spacer $spacer $spacer !important;
  margin: 0 0 $spacer 0 !important;
  box-shadow: none;
}
legend {
  width: inherit;
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: $spacer;
  margin-bottom: 0;
}
.fieldset-wrapper {
  padding: 10px 15px;
}

#autocomplete {
  position: absolute;
  background: #fff;
  border: 1px solid $gray-200;
  border-radius: $border-radius;
  ul, ol {
    padding: 10px 0;
    margin: 0;
    list-style: none;
    max-height: 300px;
    overflow: auto;
    > li {
      padding: 10px 10px;
      margin: 0 10px 5px 10px;
      cursor: pointer;
      border-radius: $border-radius;
      background-color: $gray-200;
      color: #666;
      &:hover {
        background: theme-color(primary);
        color: #fff;
      }
    }
  }
}

.pager {
  @extend .pagination;

  > li {
    @extend .page-item;
    > a {
      @extend .page-link;
    }
    &.pager-current,
    &.pager-ellipsis {
      @extend .page-link;
      cursor: default;
      &:hover {
        background: #fff;
      }
      &,
      &:hover {
        color: #444;
      }
    }

    &.first {
      &,
      > a {
        margin-left: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &.last {
      &,
      > a {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}