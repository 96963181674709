.methodes-evaluation{
	h2{
		font-family: $font-family-sans-serif;
		color: #333;
		font-size: 1.6rem;
		font-weight: bold;
	}
}

#block-menu-menu-pied-de-page{
	float: right;
	.nav{
		li{
			list-style: none;
			margin-right: 0.25rem;
			a{
				color: white;
			}
		}
	}
}

.modalites-connexion{
	h2{
		font-size: 1.5rem;
	}
	.content{
		font-size: 0.9rem;
	}
}
#block-masquerade-masquerade{
	margin-top: 2rem;
	h2{
			font-size: 1.5rem;
		}
	#masquerade-block-1{
		margin-top: 0.5rem;

		.container-inline{
			display: flex;
			align-items: center;
			.form-item-masquerade-user-field{
				margin: 0;
			}
		}
	}
}

.view-equipe-irfaf{
	.views-field{
		text-align: center;
	}
	.views-field-field-photo{
		img{
			@extend .img-fluid;
		}
	}
}