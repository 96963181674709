 #registration-form .form-item-count{
display: none;
}

#edit-field-dates-und-0-field-matin-h-und-0-field-matin,
#edit-field-dates-und-0-field-apres-midi-h-und-0-field-apres-midi{
	margin-bottom: 0;
}
#field-dates-values .field-name-field-lieu .form-item,
table[id^="field-dates-values"] .field-name-field-lieu .form-item,
#field-dates-values .field-name-field-info .form-item,
table[id^="field-dates-values"] .field-name-field-info .form-item{
	margin-right: 14px;
}
#field-dates-values fieldset,
table[id^="field-dates-values"] fieldset{
	margin-bottom: 5px;
	padding: 0 !important;
}
.field-name-field-a-confirmer .form-type-radio,
.field-name-field-ajout-avnt .form-type-radio,
.field-type-field-collection.field-name-field-matin-h .radio,
.field-type-field-collection.field-name-field-apres-midi-h .radio{
	display: flex;
	margin: 0;
}
.field-type-field-collection.field-name-field-matin-h,
.field-type-field-collection.field-name-field-apres-midi-h{
	width: 100%;
}

.field-type-field-collection.field-name-field-matin-h .form-type-radios,
.field-type-field-collection.field-name-field-apres-midi-h .form-type-radios{
	width: 100%;
	display: flex;
	margin-bottom: 5px;
	.form-radios{
		display: flex;
	}
}
.field-type-field-collection.field-name-field-matin-h .form-item,
.field-type-field-collection.field-name-field-apres-midi-h .form-item{
	margin: 0 0.25rem 0 0;
}
.ui-timepicker-table td a{
	width: 2em;
	padding: 2px;
	text-align: center;
	font-size: 12px;
}	


#field-dates-values td:not(.field-multiple-drag),
table[id^="field-dates-values"] td:not(.field-multiple-drag) ,
table[id^="field-dates-values"] td:not(.field-multiple-drag) .ajax-new-content{
	display: flex;
	flex-wrap: wrap;
}
#field-dates-values .field-name-field-date,
#field-dates-values .field-name-field-matin-h,
#field-dates-values .field-name-field-apres-midi-h,
table[id^="field-dates-values"] .field-name-field-date,
table[id^="field-dates-values"] .field-name-field-matin-h,
table[id^="field-dates-values"] .field-name-field-apres-midi-h{
	width: calc(33% - 0.75rem);
	margin-right: 0.75rem;
	div[class*="field-horaires"]{
		.fieldset-wrapper{
			display: flex;
		}
	}
}
#field-dates-values .field-name-field-a-confirmer,
#field-dates-values .field-name-field-lieu,
#field-dates-values .field-name-field-ajout-avnt,
#field-dates-values .field-name-field-info,
#field-dates-values .field-name-field-groupe,
#field-dates-values .remove-button,
table[id^="field-dates-values"] .field-name-field-a-confirmer,
table[id^="field-dates-values"] .field-name-field-lieu,
table[id^="field-dates-values"] .field-name-field-ajout-avnt,
table[id^="field-dates-values"] .field-name-field-info,
table[id^="field-dates-values"] .field-name-field-groupe,
table[id^="field-dates-values"] .remove-button{
	width: calc(20% - 0.75rem);
	margin-right: 0.75rem;
}
#field-dates-values .remove-button,
table[id^="field-dates-values"] .remove-button{
	height: 35px;
}
#egoc-add-registrants-form,
#irfaf-parcours-form,
form[id^="ajout-evenements-form"]{
	.form-item{
		margin-right: 1rem;
	}
}
form[id^="ajout-evenements-form"]{
	.help-block{
		font-size: 0.9rem;
	}
	.form-item{
		margin-top: 0;
		margin-bottom: 0;
	}
	div[id^="edit-ajout-evenements-"][id$="-ligne-3"]{
		.form-item:not(:first-child){
			width: 25%;
		}
	}
	
}

.form-inline label{
	display: flex;
-webkit-box-align: center;
align-items: center;
margin-bottom: 0;
}


#edit-field-dates{
	font-size: 0.8em;
	.help-block{
		font-size: 0.9em;
	}
	label{
		display: flex;
		margin-right: 0.5rem;
		.form-radio{
			margin-right: 0.25rem;
		}
	}
}

.container-inline-date .form-item, .container-inline-date .form-item input{
	width: revert;
}

input[type="radio"], input[type="checkbox"]{
		margin-right: 0.5rem;
	}

	#block-egoc-convention-add-conv-block,
	#block-egoc-convention-add-conv-block{
		width: calc(50% - 0.5rem);
		float: left;
		margin-bottom: 2rem;
	}
	#block-egoc-convention-add-conv-block{
		margin-right: 1rem;
	}

	#event-node-form{
		clear: both;
	}

	#edit-field-convention,
	#edit-field-avenants{
		display: none;
	}