/* 
Theme Name: Bootstrap 4 Sass
Description: Bootstrap 4 with Sass
*/

// import files
@import "import";

/* GENERAL STYLES
-------------------------------------------------*/

body {
    background: $white;
}
.navbar-light .navbar-toggler{
    border: 0 none !important;
}
.navbar-logo{
    max-height: 100px;
    margin: 1rem 1rem 1rem 0;
    @include media-breakpoint-up(lg) {
        max-height: 400px;
        width: auto;
        display: block;
        margin: 0 auto;
    }
}
#block-system-user-menu{
    ul.nav{
        display: flex;
        justify-content: center;
    }
}
/* Back to top */
#scrollBackToTop{
    position: fixed;
    display: none;
    right: 20px;
    bottom: 80px;
    z-index: 1;
    background: white;
    padding: 0.25rem;
    border: 1px solid #CCC;
    border-radius: 0.25rem;
    cursor: pointer;
}


/* COLORS 
--------------------------------------------------*/

a {
    color: $accent-shade;
}

.bg-inverse {
    background: $primary-shade !important;
}

.site-footer {
    background: $accent-shade !important;
}

.menu--main li a {
    color: $primary-shade;
}

.menu--account li a {
    color: $primary-shade;
}

.site-name-slogan a {
    text-decoration: none;
}

.card-group img {
    margin: 0;
    width: 100%;
    height: auto;
}

.card-group .field--type-image {
    margin: 0
}

#carouselAccueil{
    .carousel-caption{
        top: 50%;
        transform: translateY(-50%);
        bottom: initial;
    }
}

blockquote{
    border-left: 7px solid $primary-shade;
    padding-left: 1rem;
}

.form-control{
    margin-top: 0 !important;
}
// .table th, .table td{
//     padding: 0.25rem;
// }
table tr.even, table tr.odd{
    background: transparent;
}
form .field-multiple-table td.field-multiple-drag{
    vertical-align: middle;
}
.tabledrag-handle .handle {
    background: transparent !important;
  margin-right: 10px;
  display: inline-block;
  font-family: "FontAwesome";
font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: "\f0b2";
  }
}
a.tabledrag-handle {
  cursor: move !important;

  ~ .form-group {
    display: inline-block;
    width: 70%;
  }
}

.bloc-egoc{
    margin-top: 1.5rem;
    h2{
        background: url(../images/logo-egoc.png) no-repeat;
        padding-left: 10rem;
        height: 150px;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
}
#home-hero{
    .hero-text{
        margin-top: -5rem;
        @include media-breakpoint-up(lg) {
            margin-top: -10rem;
        }
    }
    img.hero{
        object-fit: cover;
        width: 100%;
    }
    .divider{
        position: absolute;
        bottom: -1px;
        width: 100%;
        svg{
            background: 0 0 !important;
            height: 6vw;
            * {
                fill: #fff;
            }
        }
    }
}