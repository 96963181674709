/*
----------------------------
Note appréciation formateur
----------------------------
*/
.view-note-appr-formateur .content,
.view-note-formateur-par-groupe .content{
	border: 1px solid #000;
	padding: 10px 20px 20px;	
	font-size: 11px;
}
.view-note-appr-formateur .views-field,
.view-note-formateur-par-groupe .views-field{
	width: calc(50% - 10px);
	float: left;	
}
.view-note-appr-formateur .views-field-field-catevent,
.view-note-formateur-par-groupe .views-field-field-catevent{
	width: 100%;
	border-bottom: 1px dashed #333;
	padding-bottom: 10px;
	margin-bottom: 5px;
	text-align: center;
}
.view-note-appr-formateur .views-label,
.view-note-formateur-par-groupe .views-label{
	font-weight: bold;	
}
.view-note-appr-formateur .views-field:nth-child(odd),
.view-note-formateur-par-groupe .views-field:nth-child(odd){
	margin-right: 10px;
}

.view-note-appr-formateur .fake-block,
.view-note-formateur-par-groupe .fake-block{
	min-height: 90px;
}
