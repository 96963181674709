.fiche-sidebar{
	&.light{
		color: $white;
		a{
		color: $white;

		}
	}
	&.dark{
		a{
		color: $dark;

		}
	}

	.field{
		margin-bottom: 1rem;
		p:last-child{
			margin-bottom: 0;
		}
	}
}
#block-views-sess-form-connexes-block{
	.views-row:not(:last-child){
		margin-bottom: 0.5rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid $primary;
		
	}
}