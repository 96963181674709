.view-admin-views-node{
	.views-exposed-widget.views-submit-button{
		clear: left;
		margin-right: 0.5rem;
	}

}

.view-catalogue-formations{
	h3{
		margin-bottom: 0;
		a{
			text-decoration: none;
		}
	}
}

#views-form-advanced-registration-formations-page{
	table{
		select.form-control{
			min-width: 100px;
		}
	}
}

.calendar-calendar .month-view .full td.multi-day div.monthview, .calendar-calendar .week-view .full td.multi-day div.weekview, .calendar-calendar .day-view .full td.multi-day div.dayview{
	background: transparent;
	font-size: 1.3em;
}