/*
----------------------------
Emargement
----------------------------
*/
body.page-node-registrations-emargement .logo,
body.page-node-emargement .logo{
	float: left;
	margin-right: 20px;
}
.view-emargement-inscriptions table,
.view-emargement-par-groupe table{
	border:1px solid #CCC;
	width: 100%;

}
.view-emargement-inscriptions td.views-field-field-prenom,
.view-emargement-inscriptions td.views-field-field-caf,
.view-emargement-inscriptions td.views-field-field-matin,
.view-emargement-par-groupe td.views-field-field-prenom,
.view-emargement-par-groupe td.views-field-field-caf,
.view-emargement-par-groupe td.views-field-field-matin{
	border-right: 1px solid #CCC;
}
.view-emargement-inscriptions .cross,
.view-emargement-par-groupe .cross{
	width: 100%;
	height: 100%;
	background: url(../images/cross.gif) no-repeat center fixed;
	background-size: cover;
}
.view-emargement-inscriptions .signature,
.view-emargement-par-groupe .signature{
	border: 0 none;
}
.view-emargement-inscriptions .signature .am,
.view-emargement-inscriptions .signature .pm,
.view-emargement-par-groupe .signature .am,
.view-emargement-par-groupe .signature .pm{
	width: 50%;
	color: #000;
}
.view-emargement-inscriptions .signature .am,
.view-emargement-par-groupe .signature .am{
	border-right: 1px solid #666;
}
.view-emargement-inscriptions .signature .non,
.view-emargement-par-groupe .signature .non{
	text-align : center;
	background: #CCC;
	color: #ccc;
	padding: 0;
}
.view-emargement-inscriptions .signature .non img,
.view-emargement-par-groupe .signature .non img{
	width: 100%;
	height: 40px;
}
.view-emargement-inscriptions table.views-matrix tbody tr .views-matrix-row-header, 
.view-emargement-inscriptions  table.views-matrix thead tr .views-matrix-col-header,
.view-emargement-par-groupe table.views-matrix tbody tr .views-matrix-row-header, 
.view-emargement-par-groupe  table.views-matrix thead tr .views-matrix-col-header{
	background: transparent;
	color:#666;
	padding: 4px;
	border: 1px solid;
}
.view-emargement-inscriptions th,
.view-emargement-inscriptions .views-matrix tfoot td,
.view-emargement-par-groupe th,
.view-emargement-par-groupe .views-matrix tfoot td{
	border: 1px solid #666; 
}
.view-emargement-inscriptions tr.sticky-title th,
.view-emargement-par-groupe tr.sticky-title th{
	padding: 5px;
}
/* .view-emargement-inscriptions .date-display-single,
.view-emargement-par-groupe .date-display-single{
	text-align: center;
	display: block;
} */
.view-emargement-inscriptions table.views-matrix tbody tr td.even,
.view-emargement-inscriptions table.views-matrix tbody tr td.odd,
.view-emargement-par-groupe table.views-matrix tbody tr td.even,
.view-emargement-par-groupe table.views-matrix tbody tr td.odd{
	background: transparent;
	border: 1px solid #666;
}
.view-emargement-inscriptions table.ampm,
.view-emargement-par-groupe table.ampm{
	border: 0 none;
}
.view-emargement-inscriptions table.ampm td,
.view-emargement-par-groupe table.ampm td{
	text-align: center;
	width: 50%;
}
.view-emargement-inscriptions .views-matrix thead table.ampm td.morn,
.view-emargement-par-groupe .views-matrix thead table.ampm td.morn
{
	border-right: 1px solid !important;
}
.view-emargement-inscriptions .views-matrix tfoot td,
.view-emargement-par-groupe .views-matrix tfoot td{
	text-align: center;
	padding: 5px;
}
.signimg {
	display: block;
	float: right;
}

.view-emargement-inscriptions .titre,
.view-emargement-par-groupe .titre{
	float: left;
	width: 70%;
	display: flex;
}
.view-emargement-inscriptions .titre .logo,
.view-emargement-par-groupe .titre .logo{
	width: 300px;
	height: 42px;
	margin-right: 10px;
}
