
@media print {
	@page  
	{ 
	    size: auto;   /* auto is the initial value */ 

	    /* this affects the margin in the printer settings */ 
	    margin: 5mm 5mm 5mm 5mm;  
	} 

	body  
	{ 
	    /* this affects the margin on the content before sending to printer */ 
	    margin: 0px;  
	} 

	body.page-node-registrations-emargement,
	body.page-node-emargement,
	body.page-bilan,
	body.page-node-note-appreciation-formateur{
		margin: 0 !important;
		padding: 0 !important;
	}
	body.page-bilan{
		font-size: 11px;
	}
	body.page-bilan{
		font-size: 10px;
	}
	body.page-node-registrations-emargement .main-container,
	body.page-node-emargement .main-container{
		width: 100% !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	body.page-node-registrations-emargement a#main-content,
	body.page-bilan a#main-content,
	body.page-node-registrations-emargement #page-header .navbar-nav,
	body.page-node-registrations-emargement ul.tabs--primary,
	body.page-node-registrations-emargement ul.tabs--secondary,
	body.page-node-registrations-emargement .breadcrumb,
	body.page-node-registrations-emargement .page-header,
	body.page-node-registrations-emargement #navbar,
	body.page-node-registrations-emargement #admin-menu,
	body.page-node-registrations-emargement #block-system-user-menu,
	body.page-node-emargement #page-header .navbar-nav,
	body.page-node-emargement ul.tabs--primary,
	body.page-node-emargement ul.tabs--secondary,
	body.page-node-emargement .breadcrumb,
	body.page-node-emargement .page-header,
	body.page-node-emargement #navbar,
	body.page-node-emargement #admin-menu,
	body.page-node-emargement #block-system-user-menu,
	body.page-bilan #admin-menu{
		display:none !important;
	}

	body.page-node-registrations-emargement .logo,
	body.page-node-emargement .logo{
		float: left;
		margin-right: 20px;
	}

	a:link:after, a:visited:after {
	  content: "";
	}
	th, td {
	  border: 1pt solid #000;
	  padding: 2px;
	}

	#bilan th,
	#bilan td{
		border:0; 
	}
	#bilan .table-bordered{
		border-top: 1px solid #333;
	}
	#bilan .table-bordered > thead > tr > th, 
	#bilan .table-bordered > tbody > tr > th, 
	#bilan .table-bordered > tfoot > tr > th, 
	#bilan .table-bordered > thead > tr > td, 
	#bilan .table-bordered > tbody > tr > td, 
	#bilan .table-bordered > tfoot > tr > td,
	#bilan .panel-default{
		border: 1px solid #333 !important;
	}
	#bilan .header thead {display: table-header-group;}
	#bilan thead:nth-of-type(2n) {
	  display: table-row;
	}
	/*#bilan thead tr th,
	#bilan .panel-heading{
		border-right: 1px solid #333 !important;
		position: relative;
		font-weight: bold;
		padding: 0;
	}*/
	#bilan .ontop{
		position: absolute;top: 5px; left: 5px;
	}
	#bilan .panel{
		border-radius: 0;
	}
	#bilan .border-right-w{
		border-right:2px solid white !important
	}
	#bilan .border-bot-w{
		border-bottom:2px solid white !important
	}
	/*#bilan thead img{
		margin-bottom: 20px;
	}*/
	table{
		padding: 2px;
	}
	.view-emargement-inscriptions tr,
	.view-emargement-par-groupe tr{
		height: 40px !important;
	}

	.view-emargement-inscriptions .signature td,
	.view-emargement-inscriptions .signature .pm,
	.view-emargement-par-groupe .signature td,
	.view-emargement-par-groupe .signature .pm{
		border: 0 none !important;
	}
	.view-emargement-inscriptions .signature .am,
	.view-emargement-par-groupe .signature .am{
		border-right: 1px solid #666 !important;
	}
	.view-emargement-inscriptions .signature,
	.view-emargement-inscriptions .signature td,
	.view-emargement-par-groupe .signature,
	.view-emargement-par-groupe .signature td{
		height: 100% !important;
	}
	.view-emargement-inscriptions .signature .non,
	.view-emargement-par-groupe .signature .non{
		text-align : center;
		background: #CCC !important;
		color: #CCC !important;
		padding:0 !important; 
	}
	.view-emargement-inscriptions .views-matrix thead table.ampm,
	.view-emargement-inscriptions .views-matrix thead table.ampm td,
	.view-emargement-par-groupe .views-matrix thead table.ampm,
	.view-emargement-par-groupe .views-matrix thead table.ampm td
	{
		border: 0 none !important;
	}
	.view-emargement-inscriptions .views-matrix thead table.ampm td.morn,
	.view-emargement-par-groupe .views-matrix thead table.ampm td.morn
	{
		border-right: 1px solid !important;
	}
	.view-emargement-inscriptions .views-matrix tfoot td,
	.view-emargement-par-groupe .views-matrix tfoot td{
		text-align: center !important;
		padding: 5px;
	}


	footer.footer{
		display: none !important;
	}
	#attestation{
		margin-top: 24px;
	}
	#attestation .header{
	margin-bottom: 12px;
	}
	.page-node-convocation .footer,
	.page-node-programme .footer,
	.page-registration-attestation-html .footer,
	.page-node-note-appreciation-formateur .footer,
	.page-node-note-appreciation-formateur .footer{
		position: fixed;
		bottom: 10px;
		width: 90%;

	}
	.page-registration-attestation-html .signature{
	    
	}
	.img-responsive {
	    display: block;
	    height: auto;
	    max-width: 100%;
	}
	body.page-node-note-appreciation-formateur #note-form.container{
	width: 100%;
	}
}
