/* VARIABLES */

// colors
$primary: #0062ad;
$primary-shade: #0062ad;
$primary-light: lighten($primary-shade, 37%);
$primary-lighter: #cce9ff;
$primary-dark: #0d3f66;
$accent-shade: #0079C0;
$accent-light: lighten($accent-shade, 37%);
$accent-dark: darken($accent-shade, 12%);
$turquoise: #019D9C;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000000;
// Reassign color vars to semantic color scheme
$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;
$white: #fff;
$gray-100: #919191;
$gray-800: #524f52;
$theme-colors: ( 
	primary: $accent-shade, 
	primary-light: $primary-light,
	primary-lighter: $primary-lighter, 
	secondary: $primary-dark, 
	third: $turquoise, 
	success: $green, 
	info: $cyan, 
	warning: $yellow, 
	danger: $red, 
	light: $gray-100, 
	dark: $gray-800
);
$brand-primary: $accent-shade;
//$brand-success: $green;
//$brand-info: $teal;
//$brand-warning: $orange;
//$brand-danger: $red;
$brand-inverse: $primary-shade;
// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-800;
$inverse-bg: $primary-shade;
$inverse-color: $white;
// Links
//
// Style anchor elements.
$link-color: $accent-shade;
$link-decoration: none;
$link-hover-color: $accent-dark;
$link-hover-decoration: underline;
// Comments
$comment-spacer-x: 1.25em;
$comment-spacer-y: 1.25em;
// Responsive font sizes
$enable-responsive-font-sizes: true;
$enable-shadows: false;
$enable-gradients: false;

$print-page-size: auto !default;
$print-body-min-width: 992px !default;

$sizes: () !default;
$sizes: map-merge(
  (
    33: 33%
  ),
  $sizes
);

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken($state-danger-bg, 5%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;


$border-width: 1px !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-cell-padding-sm:         .3rem !default;

$table-bg:                      transparent !default;
$table-accent-bg:               rgba($black,.05) !default;
$table-hover-bg:                rgba($black,.075) !default;
$table-active-bg:               $table-hover-bg !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-200 !default;

$table-head-bg:                 $gray-200 !default;
$table-head-color:              $gray-700 !default;

$table-inverse-bg:              $gray-900 !default;
$table-inverse-accent-bg:       rgba($white, .05) !default;
$table-inverse-hover-bg:        rgba($white, .075) !default;
$table-inverse-border-color:    lighten($gray-900, 7.5%) !default;
$table-inverse-color:           $body-bg !default;